import { defineComponent } from "vue";
export default defineComponent({
    name: "Successful",
    data() {
        return {
            counter: 3,
            timer: null,
            mobile: "",
            token: "",
            icons: [
                {
                    src: "https://static.h3yun.com/common-resource/login/imgs/new_success.png",
                    alt: "success",
                },
            ],
        };
    },
    methods: {
        navigateTo() {
            const mobile = this.mobile;
            this.timer && clearInterval(this.timer);
            this.$router.push({
                name: "Login",
                query: {
                    mobile: mobile,
                    from: "password",
                },
            });
        },
    },
    created() {
        this.mobile = this.$route.params.mobile;
    },
    mounted() {
        this.timer = setInterval(() => {
            this.counter--;
            if (this.counter < 1) {
                this.navigateTo();
            }
        }, 1000);
    },
});
